body {
    margin: 0;
    font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

@media screen and (max-width: 600px) {
    body {
        overflow-x: auto;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.mui-subtitle1 {
    font-size: 1rem;
    font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    font-weight: 400;
    line-height: 1.75;
}

.mui-typography {
    margin: 0;
}

.mui-link-underlineHover {
    text-decoration: none;
}

.mui-link-underlineHover:hover {
  text-decoration: underline;
}

.mui-typography-colorPrimary {
    color: #ff84aa;
}
